import React from "react"
import {Link} from "gatsby"
import { navigate } from "gatsby"
import { handleLogin, isLoggedIn } from "../../services/auth"
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import "../css/auth/login.scss"

class Login extends React.Component {
  state = {
    username: ``,
    password: ``,
  }
  handleUpdate = event => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }
  handleSubmit = event => {
    event.preventDefault()
    document.getElementById('login-form').style.display = "none";
    document.getElementsByClassName('loading-div')[0].style.display = "block";
    handleLogin(this.state);
  }

  render() {
    if (isLoggedIn()) {
      navigate(`/portal/dashboard`)
    }
    return (
      <>
        <div id="login-div">
          <div id="login">
            <Link to="/">
              <div id="brand-logo-hdr">
                <h1 id="brand-logo-name">InstaLeaf</h1>
              </div>
            </Link>
            <h2>Login</h2>
            <form
              method="post"
              id="login-form"
              onSubmit={event => {
                if (this.handleSubmit(event)) {
                  navigate(`/portal/dashboard`)
                }
              }}
            >
              <input placeholder="Email" type="email" name="username" onChange={this.handleUpdate}/>
              <input placeholder="Password" type="password" name="password" onChange={this.handleUpdate}/>
              <input className="btn" id="login-btn" type="submit" value="Log In" />
              <Link to="/signup" id="first-link"><h6>Don't have an account? Sign Up.</h6></Link>
              {/* <Link to="/forgotPassword"><h6>Forgot password?</h6></Link> */}
            </form>
            <div className="loading-div">
              <Loader type="Oval" color="#2ecc71" height={30} width={30}/>
              <h5>Logged in. Loading profile...</h5>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default Login
