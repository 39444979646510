import * as React from "react"
import Layout from "../components/utils/layout"

import Login from "../components/auth/login"

const LogIn = () => (
  <Layout>
    <Login />
  </Layout>
)

export default LogIn
